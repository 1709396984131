import useManualSort from '@/composables/sort/manual-sort';
import useSetParents from '@/composables/goal/set-parents';
import { CASCADE_TABLE_ITEM_DRAGGED } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { UID } from 'shared/api/query/constants';
import { computed } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import { difference } from 'shared/lib/array/array';
import { goal as goalConfig, savedView as savedViewConfig } from 'shared/api/query/configs.json';
import { realignParent } from '@/lib/goal/realign';
import { reverseEdge } from 'shared/api/query/edges';
import { sortByArray } from 'shared/lib/sort';
import { useI18n } from 'vue-i18n';

export default function useGoalOperationExecutor(collection, elReader, accountSettingsSvc, goalsSvc, viewsSvc, bulkMutateSvc) {
  const i18n = useI18n();

  const multiAlignGoals = computed(() => accountSettingsSvc.accountSettings.value.usesMultiGoalAlignment);

  const sendActionToDatadog = (operation) => {
    datadogRum.addAction(CASCADE_TABLE_ITEM_DRAGGED, { sort: operation.isSort, realign: operation.isRealign });
  };

  const prepareRealign = (operation) => {
    const { toRealignIds, newParentId } = operation;
    const goals = goalsSvc.selectMultiple(toRealignIds);
    if (goals.length !== toRealignIds.length) {
      throw new Error(`cannot find goals with id: ${difference(toRealignIds, goals.map(({ uid }) => uid))}`);
    }
    const goal = goals[0];

    const parents = realignParent(goal, operation.oldParentIdMap[goal.uid], newParentId, multiAlignGoals.value);

    let nodes;
    const { changeParents } = useSetParents({
      goalsSvc: {
        selectMultiple: goalsSvc.selectMultiple,
        updateMultiple: (entities) => {
          nodes = entities;
          return new Promise((resolve) => { resolve(); });
        },
      },
      i18n,
      EventBus,
    });

    return changeParents({ goals: [goal], parents }).then(() => ({ nodes, goals: [goal], parents }));
  };

  const prepareViewSort = (list, operation) => {
    const { listAfter, listBefore } = useManualSort(savedViewConfig.edges.goalSort, (res) => res);
    if (operation.sortAfter) {
      return listAfter(viewsSvc.currentView.value)(list, operation.anchorId, operation.toSortIds);
    }
    return listBefore(viewsSvc.currentView.value)(list, operation.anchorId, operation.toSortIds);
  };

  const prepareGoalChildrenSort = (parent, list, operation) => {
    const { listAfter, listBefore } = useManualSort(goalConfig.edges.childrenSort, (res) => res);
    if (operation.sortAfter) {
      return listAfter(parent)(list, operation.anchorId, operation.toSortIds);
    }
    return listBefore(parent)(list, operation.anchorId, operation.toSortIds);
  };
  const sort = (operation) => {
    const updateViewSort = ({ entity, sortOperation }) => viewsSvc.updateSort(entity, sortOperation);
    const updateGoalChildrenSort = ({ entity, sortOperation }) => goalsSvc.updateSingle({ uid: entity.uid, childrenSort: entity.childrenSort }, {}, { sortOperation });

    const anchor = collection.value.find((el) => elReader.getId(el) === operation.anchorId);
    const dragDepth = elReader.getDepth(anchor);

    if (dragDepth === 0) {
      const list = collection.value.filter((el) => elReader.getDepth(el) === 0).map((el) => elReader.getId(el));
      return updateViewSort(prepareViewSort(list, operation));
    }
    const parent = goalsSvc.selectSingle(operation.newParentId);
    const list = parent.children.sort(sortByArray(parent.childrenSort)).map((c) => c.uid);
    return updateGoalChildrenSort(prepareGoalChildrenSort(parent, list, operation));
  };

  const realignAndSort = (operation) => {
    const payload = {};

    return prepareRealign(operation).then((preparedRealign) => {
      if (preparedRealign.nodes !== undefined) {
        payload.realign = {
          nodes: preparedRealign.nodes,
          model: goalConfig.model,
          attributes: [
            { attr: UID },
            {
              attr: goalConfig.edges.parents,
              model: goalConfig.model,
              children: [{ attr: UID }],
              default: [],
            },
            {
              attr: reverseEdge(goalConfig.edges.parents),
              alias: 'children',
              model: goalConfig.model,
              children: [{ attr: UID }],
              default: [],
            },
          ],
        };
      }

      if (preparedRealign.parents.length === 0 || !multiAlignGoals.value || operation.newParentId !== null) {
        const anchor = collection.value.find((el) => elReader.getId(el) === operation.anchorId);
        const dragDepth = elReader.getDepth(anchor);

        if (dragDepth === 0) {
          const list = collection.value.filter((el) => elReader.getDepth(el) === 0).map((el) => elReader.getId(el));
          const preparedSort = prepareViewSort([...list, ...preparedRealign.goals.map(({ uid }) => uid)], operation);
          payload.sortView = {
            nodes: [{ uid: preparedSort.entity.uid, goalSort: preparedSort.entity.goalSort }],
            model: savedViewConfig.model,
            attributes: [
              { attr: UID },
              { attr: savedViewConfig.edges.goalSort, default: [] },
            ],
            updateHookParameter: { sortOperation: preparedSort.sortOperation },
          };
        } else {
          const parent = goalsSvc.selectSingle(operation.newParentId);
          const list = parent.children.sort(sortByArray(parent.childrenSort)).map((c) => c.uid);
          const preparedSort = prepareGoalChildrenSort(parent, [...list, ...preparedRealign.goals.map(({ uid }) => uid)], operation);
          payload.sortGoalChildren = {
            nodes: [{ uid: preparedSort.entity.uid, childrenSort: preparedSort.entity.childrenSort }],
            model: goalConfig.model,
            attributes: [
              { attr: UID },
              { attr: goalConfig.edges.childrenSort, default: [] },
            ],
            updateHookParameter: { sortOperation: preparedSort.sortOperation },
          };
        }
      }

      Object.keys(payload).forEach((alias) => {
        payload[alias].alias = alias;
      });

      const bulkPayloads = [
        payload.realign,
        payload.sortView,
        payload.sortGoalChildren,
      ].filter((p) => p !== undefined);
      return bulkMutateSvc.bulkMutate(bulkPayloads);
    });
  };

  const executeDrag = (operation) => {
    if (!operation.isRealign && !operation.isSort) {
      return new Promise((resolve) => { resolve(); });
    }

    sendActionToDatadog(operation);

    if (operation.isRealign && !operation.isSort) {
      return prepareRealign(operation).then((preparedRealign) => {
        if (preparedRealign.nodes === undefined) {
          return new Promise((resolve) => { resolve(); });
        }
        return goalsSvc.updateMultiple(preparedRealign.nodes);
      });
    }

    if (!operation.isRealign && operation.isSort) {
      return sort(operation);
    }

    return realignAndSort(operation);
  };

  return { executeDrag };
}
